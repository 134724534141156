'use client'

import React, { FC, FormEvent, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import cx from 'clsx'
import Alert from '@/components/atoms/alert'
import { Button } from '@/components/atoms/button'
import BlockContent from '@/components/blocks/block-content'
import { FormFieldsProps } from '@/components/elements/dynamic-form/interface'
import useTranslation from '@/translations/resources'
import { FormData as FormDataProps, FormSubmitStatus } from '@/types/site.interface'
import { Transition } from '@headlessui/react'
import Toggle from '@ignition/library/components/atoms/toggle'
import CheckboxGroup from '@ignition/library/components/molecules/checkbox-group/checkbox-group'
import RadioGroup from '@ignition/library/components/molecules/radio-group/radio-group'
import useToggle from '@ignition/library/hooks/use-toggle'
import logger from '@ignition/library/lib/logger'
import { ConsentToggle } from './elements/consent-toggle'
import Input from './elements/input'
import { Textarea } from './elements/textarea'
import { Upload } from './elements/upload'

const styles = {
  form: 'grid grid-cols-1 gap-y-6',
  submitContainer: 'flex justify-center md:justify-start',
}

interface FormProps {
  defaultValues?: FormDataProps
  formItems: FormFieldsProps[]
  hiddenButton?: boolean
  id?: string
  onSubmit: (data: FormData, privacyEnabled?: boolean) => Promise<FormSubmitStatus>
  privacyHref?: string
}

const Form: FC<FormProps> = ({ defaultValues = {}, formItems, hiddenButton, id, onSubmit, privacyHref }) => {
  const translate = useTranslation()
  const [checked, toggleConsent] = useToggle()
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [statusAlert, setStatusAlert] = useState<FormSubmitStatus>()
  const { control, handleSubmit, formState, register, reset, setValue, trigger } = useForm<FormDataProps>({
    defaultValues,
    shouldUnregister: true,
  })
  const { isSubmitting, errors } = formState
  const submitDisabled = !checked || isSubmitting

  const inputPicker = (item: FormFieldsProps) => {
    if (!item) return null
    switch (item?._type) {
      case 'form.email':
        return (
          <Input
            {...register(item._key, {
              required: { message: item.formMessage || translate('error.form.field.required'), value: !!item.required },
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            })}
            key={item._key}
            type="email"
            placeholder={item.placeholder}
            errorMessage={errors?.[item._key]?.message}
            label={item.label}
          />
        )
      case 'form.phone':
        return (
          <Input
            {...register(item._key, {
              required: { message: item.formMessage || translate('error.form.field.required'), value: !!item.required },
              pattern: undefined,
            })}
            key={item._key}
            type="tel"
            placeholder={item.placeholder}
            errorMessage={errors?.[item._key]?.message}
            label={item.label}
          />
        )
      case 'form.textInput':
        return (
          <Input
            {...register(item._key, {
              required: { message: item.formMessage || translate('error.form.field.required'), value: !!item.required },
              pattern: undefined,
            })}
            key={item._key}
            placeholder={item.placeholder}
            errorMessage={errors?.[item._key]?.message}
            label={item.label}
          />
        )
      case 'form.switch':
        return (
          <Controller
            key={item._key}
            name={item._key}
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <Toggle
                checked={value as boolean}
                onChange={onChange}
                label={item.label}
                description={item.description}
              />
            )}
          />
        )
      case 'form.content':
        return (
          <BlockContent
            key={item._key}
            blocks={item.content}
            className="sm:text-left text-center mb-10 mx-auto max-w-[80ch] prose m-w-prose text-xl text-gray-500"
          />
        )

      case 'form.radio':
        return (
          <Controller
            key={item._key}
            name={item._key}
            control={control}
            rules={{
              required: {
                value: !!item.required,
                message: item.formMessage || translate('error.form.field.required'),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                selected={value as string}
                onSelect={onChange}
                label={item.label}
                options={(item.options && (item.options as string[]).map((x) => ({ text: x, id: x }))) || []}
                errorMessage={errors?.[item._key]?.message}
              />
            )}
          />
        )
      case 'form.checkbox':
        return (
          <Controller
            key={item._key}
            name={item._key}
            control={control}
            rules={{
              required: {
                value: !!item.required,
                message: item.formMessage || translate('error.form.field.required'),
              },
            }}
            render={({ field: { value } }) => (
              <CheckboxGroup
                selected={(value as string[]) || []}
                onChange={(selected) => setValue(item._key, selected)}
                label={item?.label}
                options={(item.options && (item.options as string[]).map((x) => ({ text: x, id: x }))) || []}
                errorMessage={errors?.[item._key]?.message}
              />
            )}
          />
        )

      case 'form.uploadFile':
        return (
          <Controller
            key={item._key}
            name={item._key}
            control={control}
            defaultValue={[]}
            rules={{
              required: {
                value: !!item.required,
                message: item.formMessage || translate('error.form.field.required'),
              },
            }}
            render={({ field: { value } }) => (
              <Upload
                onChange={(e: React.ChangeEvent<HTMLInputElement> & React.DragEvent<HTMLElement>) => {
                  const fileList = e.target.files || e.dataTransfer.files
                  if (fileList && fileList[0]) {
                    // at least one file has been added
                    setValue(item._key, [...(value as File[]), ...Array.from(fileList)])
                  }
                  trigger(item._key)
                }}
                fileTypes={item.fileTypes}
                name={item._key}
                files={value as File[]}
                onDeleteFile={(fileName: string) => {
                  setValue(
                    item._key,
                    (value as File[]).filter((file) => file.name !== fileName),
                  )
                  trigger(item._key)
                }}
                errorMessage={errors?.[item._key]?.message}
              />
            )}
          />
        )
      case 'form.textArea':
        return (
          <Textarea
            {...register(item?._key, {
              required: {
                message: item?.formMessage || translate('error.form.field.required'),
                value: !!item?.required,
              },
              pattern: undefined,
            })}
            key={item?._key}
            placeholder={item?.placeholder}
            errorMessage={errors?.[item._key]?.message}
            label={item?.label}
          />
        )
      default:
        return <div className="hidden" key={item?._key}>{`Unknown field: ${item?._type}`}</div>
    }
  }

  return (
    <form
      id={id}
      action="#"
      method="POST"
      className={styles.form}
      onSubmit={handleSubmit(async (data, evt: FormEvent<HTMLFormElement>) => {
        const form = evt.target as HTMLFormElement
        const formData = new FormData(form)
        // append missing fields into the formData
        const requestData = Object.fromEntries(formData)
        Object.keys(data).forEach((key) => !requestData[key] && formData.append(key, data[key] as string))

        logger.info('Submitting form...', data)
        const status = await onSubmit(formData, checked)
        setStatusAlert(status)
        setShowAlert(true)
        if (status === 'success') reset()
      })}
    >
      <input className="hidden" name="formId" defaultValue={id} />
      {formItems?.map((item) => inputPicker(item))}
      <ConsentToggle checked={checked} onChange={toggleConsent} href={privacyHref} />
      <Transition show={showAlert} className="overflow-hidden">
        <Transition.Child
          enter="ease-in-out transform duration-[500ms]"
          enterFrom="-translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="ease-out duration-[500ms]"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="translate-x-full opacity-0"
        >
          <Alert
            status={statusAlert === 'success' ? 'success' : 'error'}
            title={
              statusAlert === 'success'
                ? 'Email wurde erfolgreich Versendet.'
                : 'Wir konnten Ihre E-Mail nicht senden, bitte versuchen Sie es später erneut.'
            }
            onClose={() => setShowAlert(false)}
          />
        </Transition.Child>
      </Transition>
      <div className={cx({ hidden: hiddenButton }, styles.submitContainer)}>
        <Button type="button" disabled={submitDisabled} submit>
          Abschicken
        </Button>
      </div>
    </form>
  )
}

export default Form

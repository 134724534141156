import React, { FC } from 'react'
import SafeLink from '@ignition/library/components/atoms/safe-link'
import Toggle from '@ignition/library/components/atoms/toggle'

interface ConsentToggleProps {
  checked?: boolean
  onChange: (checked: boolean) => void
  href?: string
}

export const ConsentToggle: FC<ConsentToggleProps> = ({ checked = false, onChange, href = '#' }) => {
  return (
    <Toggle
      checked={checked}
      onChange={onChange}
      className="py-4"
      description={
        <>
          Mit dem Versenden dieses Kontaktformualars akzeptieren Sie unsere{' '}
          <SafeLink href={href} target="_blank">
            <span className="text-primary hover:underline">Datenschutzrichtlinien.</span>
          </SafeLink>
        </>
      }
    />
  )
}

import { FC, forwardRef, LegacyRef, SyntheticEvent, useState } from 'react'
import clsx from 'clsx'
import { FilePlus } from 'phosphor-react-sc'
import { FileType } from '@/components/elements/dynamic-form/interface'
import FilesGrid from './files-grid'

const styles = {
  container: (dragActive: boolean, isError: boolean) =>
    clsx(
      'rounded-md border-2 border-dashed px-6 pt-5 pb-6 relative',
      dragActive ? 'border-primary' : isError ? 'border-red-300' : 'border-gray-300',
    ),
  fileIcon: 'mx-auto h-12 w-12 text-gray-400',
  label:
    'block relative cursor-pointer rounded-md font-medium text-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-primary/90 focus-within:ring-offset-2 hover:text-primary/90',
  dragLayer: 'absolute w-full h-full rounded-md inset-0',
  errorMessage: 'mt-2 text-sm text-red-600',
}

interface UploadProps {
  fileTypes?: FileType[]
  errorMessage?: string
  files?: File[]
  name?: string
  onChange: (e: SyntheticEvent) => void
  onDeleteFile: (fileName: string) => void
}

export const Upload: FC<UploadProps> = forwardRef(
  ({ fileTypes, errorMessage, files, name, onChange, onDeleteFile }, ref: LegacyRef<HTMLInputElement>) => {
    const [dragActive, setDragActive] = useState(false)
    const isError = !!errorMessage

    const handleDragEnter = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(true)
    }

    const handleDragLeave = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(false)
    }

    const handleDragOver = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(true)
    }

    const handleDrop = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(false)
      onChange(e)
    }

    return (
      <div>
        <div className={styles.container(dragActive, isError)} onDragEnter={handleDragEnter}>
          <div className="flex justify-center">
            <div className="space-y-1 text-center">
              <FilePlus className={styles.fileIcon} aria-hidden="true" weight="light" />
              <div className="text-sm text-gray-600">
                <label htmlFor="fileUpload" className={styles.label}>
                  <span>Laden Sie Ihre Bewerbungsmappe hier hoch</span>
                  <input
                    ref={ref}
                    id="fileUpload"
                    name={name}
                    onChange={onChange}
                    type="file"
                    className="sr-only"
                    accept={getAcceptValue(fileTypes)}
                    multiple={true}
                  />
                </label>
                <p>oder ziehen Sie eine Datei in dieses Feld</p>
              </div>
              <p className="text-xs text-gray-500">PDF, MS Word bis 10MB</p>
            </div>
          </div>
          <FilesGrid files={files} onDeleteFile={onDeleteFile} className="mt-4" />
          {dragActive && (
            <div
              className={styles.dragLayer}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            ></div>
          )}
        </div>
        {isError && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>
    )
  },
)

const getAcceptValue = (fileTypes: FileType[] = []): string => {
  return fileTypes.map((x) => fileTypeToAcceptValue(x)).join(',')
}

const fileTypeToAcceptValue = (fileType: FileType) => {
  switch (fileType) {
    case 'document':
      return 'application/pdf'
    case 'file':
      return 'application/msword'
    case 'image':
      return 'image/png,image/jpeg'
    case 'audio':
      return 'audio/*'
    case 'video':
      return 'video/*'
    default:
      return ''
  }
}

Upload.displayName = 'Upload'

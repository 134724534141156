import { FC } from 'react'
import Label from '../../atoms/label'
import { RadioItem } from './radio-item'

const styles = {
  errorMessage: 'mt-2 text-sm text-red-600',
}

interface Option {
  id: string
  text: string
  subText?: string
}

interface RadioGroupProps {
  description?: string
  errorMessage?: string
  label?: string
  onSelect: (selected: string) => void
  options: Option[]
  selected: string
}

const RadioGroup: FC<RadioGroupProps> = ({ description, errorMessage, label, onSelect, options, selected }) => {
  const isError = !!errorMessage
  return (
    <div>
      {label && (
        <Label srOnly={false} className="mb-1">
          {`${label}:`}
        </Label>
      )}
      {description && <p className="text-sm text-gray-500">{description}</p>}
      <fieldset className="mt-4">
        <legend className="sr-only">{label}</legend>
        <div className="space-y-4">
          {options.map((option) => (
            <RadioItem
              key={option.id}
              id={option.id}
              checked={option.id === selected}
              name={option.text}
              onChange={() => onSelect(option.id)}
              label={option.text}
              description={option.subText}
            />
          ))}
        </div>
      </fieldset>
      {isError && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  )
}

export default RadioGroup

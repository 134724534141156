import { ChangeEvent, FC } from 'react'
import Label from '../../atoms/label'
import Text from '../../atoms/text'

const styles = {
  container: 'relative flex items-start',
  buttonContainer: 'flex h-6 items-center',
  textContainer: 'ml-3 text-sm leading-6',
  radioButton: 'h-4 w-4 rounded border-gray-300 text-primary focus:border-primary focus:ring-primary',
  label: 'text-gray-700',
}

interface RatioItemProps {
  checked?: boolean
  id?: string
  name?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  subText?: string
  label: string
  description?: string
}

export const RadioItem: FC<RatioItemProps> = ({ checked, id, name, onChange, label, description }) => {
  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <input id={id} name={name} type="radio" onChange={onChange} checked={checked} className={styles.radioButton} />
      </div>
      <div className={styles.textContainer}>
        <Label htmlFor={id} className={styles.label}>
          {label}
        </Label>
        {description && (
          <Text as="p" className="text-gray-400">
            {description}
          </Text>
        )}
      </div>
    </div>
  )
}

import { FC } from 'react'
import Label from '../../atoms/label'
import { CheckboxItem } from './checkbox-item'

const styles = {
  errorMessage: 'mt-2 text-sm text-red-600',
}

interface Option {
  id: string
  text: string
  subText?: string
}

interface CheckboxGroupProps {
  description?: string
  errorMessage?: string
  label?: string
  onChange: (selected: string[]) => void
  options: Option[]
  selected: string[]
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({ description, errorMessage, label, onChange, options, selected }) => {
  const isError = !!errorMessage
  return (
    <div>
      {label && (
        <Label srOnly={false} className="mb-1">
          {`${label}:`}
        </Label>
      )}
      {description && <p className="text-sm text-gray-500">{description}</p>}
      <fieldset className="mt-4">
        <legend className="sr-only">{label}</legend>
        <div className="space-y-4">
          {options.map((option) => (
            <CheckboxItem
              key={option.id}
              id={option.id}
              checked={selected.includes(option.id)}
              name={option.text}
              onChange={() =>
                onChange(
                  selected.includes(option.id) ? selected.filter((x) => x !== option.id) : [...selected, option.id],
                )
              }
              label={option.text}
              description={option.subText}
            />
          ))}
        </div>
      </fieldset>
      {isError && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  )
}

export default CheckboxGroup

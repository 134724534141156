import { FC, forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, LegacyRef, SyntheticEvent } from 'react'
import clsx from 'clsx'
import Label from './label'

const styles = {
  input: (isError: boolean, modalInput: boolean) =>
    clsx(
      !modalInput ? 'block w-full py-3' : 'relative py-2 pl-3 w-full lg:w-8/12 pr-10 sm:text-sm',
      'rounded-md shadow-sm px-4',
      isError
        ? 'placeholder-red-300 border-red-300 focus:border-red-500 focus:ring-red-500'
        : 'placeholder-gray-500 border-gray-300 focus:border-primary focus:ring-primary',
    ),
  errorMessage: 'mt-2 text-sm text-red-600',
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  autoComplete?: string
  errorMessage?: string
  modalInput?: boolean // TODO: it is not clear what this prop actually means. Could be better if we replace it with classname prop.
  id?: string
  name: string
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void
  placeholder?: string
  type?: HTMLInputTypeAttribute
  value?: string
  label?: string
}

const Input: FC<InputProps> = forwardRef(
  (
    { autoComplete, errorMessage, id, placeholder, type = 'text', modalInput = false, label, ...props },
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    const isError = !!errorMessage
    return (
      <div>
        {!!label && (
          <Label htmlFor={id} srOnly={false} className="mb-1">
            {`${label}:`}
          </Label>
        )}
        <input
          ref={ref}
          type={type}
          id={id}
          autoComplete={autoComplete}
          className={styles.input(isError, modalInput)}
          placeholder={placeholder}
          {...props}
        />
        {isError && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>
    )
  },
)

Input.displayName = 'Input'

export default Input

import { FC, ReactNode } from 'react'
import clsx from 'clsx'

interface LabelProps {
  htmlFor?: string
  children: ReactNode | string
  className?: string
  srOnly?: boolean
}

const Label: FC<LabelProps> = ({ htmlFor, children, className, srOnly }) => {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx(className, srOnly && 'sr-only', 'inline-block text-sm font-medium text-gray-900')}
    >
      {children}
    </label>
  )
}

export default Label
